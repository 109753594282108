const cameras = [
    {
        img:"camera_varifocal.png",
        titre:"Caméra varifocal"
    },
    {
        img:"camera_infrarouge.png",
        titre:"Caméra infrarouge"
    },
    {
        img:"camera_exterieure.png",
        titre:"Caméra exterieur"
    },
    {
        img:"dome_motorise.png",
        titre:"Dôme motorisé"
    },
    {
        img:"camera11.png",
        titre:"camera11"
    },
    {
        img:"camera22.png",
        titre:"camera22"
    },
    {
        img:"camera3.png",
        titre:"camera33"
    },
    {
        img:"camera44.png",
        titre:"camera44"
    },
    {
        img:"camera55.png",
        titre:"camera55"
    },
    {
        img:"camera66.png",
        titre:"camera66"
    },
    {
        img:"camera77.png",
        titre:"camera77"
    },
    {
        img:"dome_motorise.png",
        titre:"camera88"
    }

]

export default cameras;