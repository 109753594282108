import React from 'react'
import LogoPartenaire from '../../components/Home/PartenaireLogo'
export default function IlNousOntFaitConfianceHome() {
    return (
        <div className="il_nous_font_confiance">
            <h3>Ils nous font confiance</h3>
            <LogoPartenaire/>
        </div>
    )
}
