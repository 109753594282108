const partenaires_logo = [{
    id: "1",
    img: "H_M",
    format:"png",
    largeur: 97,
    hauteur:64
    
},
{
    id: "2",
    img: "vinci",
    format:"png",
    largeur: 188,
    hauteur:106
},
{
    id: "3",
    img: "groupama",
    format:"png",
    largeur: 220,
    hauteur:120
},
{
    id: "4",
    img: "Fondation_Gustave_Roussy",
    format:"png",
    largeur: 198,
    hauteur:44
},
{
    id: "5",
    img: "pitch",
    format:"png",
    largeur: 98,
    hauteur:98
}
]

export default partenaires_logo;